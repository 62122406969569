//Get the button:
mybutton = document.getElementById("BackToTopButton");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "flex";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    const offSetTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (offSetTop > 0) {
      window.requestAnimationFrame(topFunction);
      window.scrollTo(0, offSetTop - offSetTop / 16);
    }
  };

mybutton.addEventListener("click", topFunction);
